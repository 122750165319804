"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
const Offscreen = _styled.default.span(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    position: absolute;\n    left: -200vw;\n    width: 1px;\n    height: 1px;\n    top: auto;\n"])));
var _default = exports.default = Offscreen;