import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import {
    Box,
    createTheme,
    CssBaseline,
    responsiveFontSizes,
    ThemeProvider,
} from '@mui/material'
import { withProfiler } from '@sentry/react'
import _ from 'lodash'
import React from 'react'
import useColorMode from 'react-common-eks/dist/hooks/use-color-mode'
import Layout from 'react-common-eks/dist/layout/Layout'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { footerNavItems, relatedNavItems, siteNavItems } from './data/nav-links'
import useAuth from './hooks/use-auth'
import useUserInGroups, {
    COGNITO_GROUP_ADMINS,
} from './hooks/use-user-in-groups'
import Routes from './Routes'
import createThemeOptions from './theme/create-theme-options'

const App = () => {
    useAuth()
    const user = useSelector(state => state.user)
    const isAdmin = useUserInGroups(COGNITO_GROUP_ADMINS)

    const mode = useColorMode()
    let theme = createTheme({
        palette: {
            mode,
        },
    })
    theme = createTheme(theme, createThemeOptions(theme))
    theme = responsiveFontSizes(theme)

    let memberNavItems
    if (!_.isNil(user.userData)) {
        memberNavItems = [
            {
                label: 'Profile',
                href: '/me',
            },
        ]

        if (isAdmin) {
            memberNavItems.push({
                label: 'Admin',
                href: '/admin',
            })
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />
            <Router>
                <Layout
                    title="Edwards Karate School"
                    subtitle="Office of the Headquarters"
                    memberNavItems={memberNavItems}
                    relatedNavItems={relatedNavItems}
                    siteNavItems={siteNavItems}
                    footerNavItems={footerNavItems}
                >
                    <Routes />
                </Layout>
                <Box
                    id="admin-menu"
                    position="fixed"
                    sx={{ bottom: 0, left: 0, right: 0 }}
                />
            </Router>
        </ThemeProvider>
    )
}

export default withProfiler(App)
