import Home from 'pages/Home'
import ShintoMusuRyuNihonJodoKai from 'pages/ShintoMusuRyuNihonJodoKai'
import React from 'react'
import withSuspense from 'react-common-eks/dist/components/withSuspense'
import organizations from 'react-common-eks/dist/data/organizations'
import HttpNotFound from 'react-common-eks/dist/pages/HttpNotFound'
import { useRoutes } from 'react-router-dom'

import Authorized from './components/Authorized'

const ContactUs = React.lazy(() =>
    import('react-common-eks/dist/pages/ContactUs')
)

const Dojos = React.lazy(() => import('react-common-eks/dist/pages/Dojos'))
const Event = React.lazy(() => import('react-common-eks/dist/pages/Event'))
const Events = React.lazy(() => import('react-common-eks/dist/pages/Events'))
const Membership = React.lazy(() =>
    import('react-common-eks/dist/pages/Membership')
)

const MoreOrganizations = React.lazy(() =>
    import('react-common-eks/dist/pages/MoreOrganizations')
)

const News = React.lazy(() => import('react-common-eks/dist/pages/News'))
const NewsArticle = React.lazy(() =>
    import('react-common-eks/dist/pages/NewsArticle')
)

const Photos = React.lazy(() => import('react-common-eks/dist/pages/Photos'))
const PhotoSet = React.lazy(() =>
    import('react-common-eks/dist/pages/PhotoSet')
)

const ApplyWizard = React.lazy(() => import('./pages/ApplyWizard'))
const MembershipApplication = React.lazy(() =>
    import('./pages/MembershipApplication')
)

const AdminCUDMenu = React.lazy(() => import('./components/AdminCUDMenu'))

const Admin = React.lazy(() => import('./pages/Admin'))
const ArticleDeleter = React.lazy(() => import('./components/ArticleDeleter'))
const ArticleEditor = React.lazy(() => import('./pages/ArticleEditor'))
const EventDeleter = React.lazy(() => import('./components/EventDeleter'))
const EventEditor = React.lazy(() => import('./pages/EventEditor'))
const Profile = React.lazy(() => import('pages/Profile'))

let Routes = () => {
    return useRoutes([
        { path: '/', element: <Home /> },
        { path: 'locations', element: <Dojos /> },
        {
            path: 'more-organizations',
            element: <MoreOrganizations organizations={organizations} />,
        },
        { path: 'contact-us', element: <ContactUs /> },
        {
            path: 'events',
            element: <AdminCUDMenu param="eventId" />,
            children: [
                { path: '', element: <Events /> },
                {
                    path: ':eventId',
                    element: <Event />,
                    children: [{ path: 'delete', element: <EventDeleter /> }],
                },
                { path: 'add', element: <EventEditor /> },
                { path: ':eventId/edit', element: <EventEditor /> },
            ],
        },
        { path: 'membership', element: <Membership /> },
        {
            path: 'membership/application/:membershipApplicationId',
            children: [
                {
                    path: '',
                    element: <MembershipApplication />,
                },
                {
                    path: ':action',
                    element: <MembershipApplication />,
                },
            ],
        },
        { path: 'membership/apply/:associationId', element: <ApplyWizard /> },
        {
            path: 'news',
            element: <AdminCUDMenu param="articleId" />,
            children: [
                { path: '', element: <News /> },
                {
                    path: ':articleId',
                    element: <NewsArticle />,
                    children: [{ path: 'delete', element: <ArticleDeleter /> }],
                },
                { path: 'add', element: <ArticleEditor /> },
                { path: ':articleId/edit', element: <ArticleEditor /> },
            ],
        },
        { path: 'photo-gallery', element: <Photos /> },
        { path: 'photo-gallery/:photoSetId', element: <PhotoSet /> },
        {
            path: 'shinto-musu-ryu-nihon-jodo-kai',
            element: <ShintoMusuRyuNihonJodoKai />,
        },
        {
            path: 'me',
            element: <Authorized login />,
            children: [
                {
                    path: '',
                    element: <Profile />,
                },
            ],
        },
        {
            path: 'admin/*',
            element: <Admin />,
        },
        { path: '*', element: <HttpNotFound /> },
    ])
}

Routes.defaultProps = {}

Routes = withSuspense(Routes)

export default Routes
