import _ from 'lodash'
import PropTypes from 'prop-types'
import { Fragment, useEffect } from 'react'
import HttpForbidden from 'react-common-eks/dist/pages/HttpForbidden'
import HttpUnauthorized from 'react-common-eks/dist/pages/HttpUnauthorized'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'

import useUserInGroups, { COGNITO_GROUPS } from '../hooks/use-user-in-groups'

const Authorized = ({ children, groups, toSafety }) => {
    const user = useSelector(state => state.user)
    const authorized = useUserInGroups(...groups)
    const navigate = useNavigate()

    const { loading } = user

    useEffect(() => {
        if (loading) return

        if (!authorized && toSafety) {
            navigate(toSafety)
        }
    }, [authorized, loading, navigate, toSafety])

    if (loading) return <Fragment />

    if (!authorized) {
        if (toSafety) return <Fragment />
        if (_.isEmpty(groups)) return <HttpUnauthorized />
        return <HttpForbidden />
    }

    return children || <Outlet />
}

Authorized.defaultProps = {
    groups: [],
}

Authorized.propTypes = {
    login: PropTypes.bool,
    groups: PropTypes.arrayOf(PropTypes.oneOf(COGNITO_GROUPS)).isRequired,
    toSafety: PropTypes.string,
}

export default Authorized
