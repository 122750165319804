import { Divider, Grid, Paper, Typography } from '@mui/material'
import Offscreen from 'react-common-eks/dist/components/Offscreen'
import Paragraph from 'react-common-eks/dist/components/Paragraph'
import useBreadcrumbs from 'react-common-eks/dist/hooks/use-breadcrumbs'
import { Helmet } from 'react-helmet'

import BioContent from './BioContent'
import BioTopRow from './BioTopRow'
import ShihanAffiliations from './ShihanAffiliations'

const Home = () => {
    useBreadcrumbs([])

    return (
        <Paragraph
            component="article"
            disableGutters
            id="person-bernard-edwards"
            itemScope
            itemType="https://schema.org/Person"
            sx={{ mt: { sm: 4 } }}
        >
            <Helmet>
                <title>
                    Office of the Headquarters :: Edwards Karate School
                </title>
            </Helmet>
            <BioTopRow />
            <Divider />
            <Paper sx={{ p: 4 }} elevation={0}>
                <Offscreen>
                    <Typography variant="h2">Shihan Bernard Edwards</Typography>
                </Offscreen>
                <Grid container spacing={2}>
                    <Grid item md={9} component="section">
                        <BioContent />
                    </Grid>
                    <Grid item md={3} component="section">
                        <ShihanAffiliations />
                    </Grid>
                </Grid>
            </Paper>
        </Paragraph>
    )
}

export default Home
