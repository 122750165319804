import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import _ from 'lodash'
import * as PropTypes from 'prop-types'
import CaptionedImage from 'react-common-eks/dist/components/CaptionedImage'
import Hero from 'react-common-eks/dist/components/Hero'
import { SHIHAN_YEARS_TEACHING } from 'react-common-eks/dist/data/settings'

const SHIHAN_PHOTO = '/images/shihan-edwards.png'

const BioTopRow = () => {
    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'))
    const mdUp = useMediaQuery(theme.breakpoints.up('md'))

    const captionImgParams = {
        fit: 'crop',
        'fp-x': 0.43,
        'fp-y': 0.19,
    }
    if (mdUp) {
        captionImgParams.ar = '3:4'
        captionImgParams.w = _.toInteger(theme.breakpoints.values.xl / 4)
    } else if (smUp) {
        captionImgParams.ar = '4:5'
        captionImgParams.w = _.toInteger(theme.breakpoints.values.md / 3)
    } else {
        captionImgParams.ar = '3:2'
        captionImgParams.w = theme.breakpoints.values.sm
    }

    return (
        <Grid
            container
            sx={{
                backgroundColor: 'grey.200',
                '@media (prefers-color-scheme: dark)': {
                    backgroundColor: 'grey.400',
                    color: theme =>
                        theme.palette.getContrastText(
                            theme.palette.grey['400']
                        ),
                },
            }}
        >
            <Grid item sm={4} md={3}>
                <Box
                    sx={[
                        {
                            lineHeight: 0,
                        },
                    ]}
                >
                    <CaptionedImage
                        itemProp="image"
                        src={SHIHAN_PHOTO}
                        params={captionImgParams}
                        width="100%"
                        aria-labelledby="name-person-bernard-edwards"
                    >
                        <span id="name-person-bernard-edwards" itemProp="name">
                            Shihan Bernard Edwards
                        </span>
                    </CaptionedImage>
                </Box>
            </Grid>
            <Grid item sm={8} md={9} component="section" alignSelf="center">
                <Hero
                    maxWidth={theme => 0.67 * theme.breakpoints.values.md}
                    margin="auto"
                >
                    <Typography paragraph sx={{ margin: 0 }}>
                        Shihan Bernard Edwards is the Chief Master Instructor of
                        the{' '}
                        <span
                            itemProp="memberOf"
                            itemScope
                            itemType="https://schema.org/Organization"
                        >
                            <span itemProp="name">
                                US Karate-Do Hakua Kai Association
                            </span>
                        </span>{' '}
                        and the President and Founder of the{' '}
                        <span
                            itemProp="memberOf"
                            itemScope
                            itemType="https://schema.org/Organization"
                        >
                            <span itemProp="name">Edwards Karate School</span>
                            <span
                                itemProp="founder"
                                itemScope
                                itemType="https://schema.org/Person"
                                itemRef="person-bernard-edwards"
                            />
                        </span>{' '}
                        and the{' '}
                        <span
                            itemProp="memberOf"
                            itemScope
                            itemType="https://schema.org/Organization"
                        >
                            <span itemProp="name">
                                Hakua Kai International Organization
                            </span>
                            <span
                                itemProp="founder"
                                itemScope
                                itemType="https://schema.org/Person"
                                itemRef="person-bernard-edwards"
                            />
                        </span>
                        .{' '}
                        <span
                            itemScope
                            itemType="https://schema.org/Organization"
                        >
                            <span itemProp="name">Edwards Karate School</span>{' '}
                            has taught traditional Japanese{' '}
                            <span
                                itemProp="parentOrganization"
                                itemScope
                                itemType="https://schema.org/Organization"
                            >
                                <span itemProp="name">Hakua Kai Karate-Do</span>
                            </span>{' '}
                            to adults and children on the{' '}
                            <span itemProp="location">
                                San Francisco Peninsula
                            </span>{' '}
                            for the last {SHIHAN_YEARS_TEACHING} years.
                        </span>
                    </Typography>
                </Hero>
            </Grid>
        </Grid>
    )
}

BioTopRow.propTypes = { children: PropTypes.node }

export default BioTopRow
