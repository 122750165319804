import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'
import _ from 'lodash'

const initialState = {
    userData: null,
    loading: false,
}

function parseCognitoUser(userData) {
    if (_.isNil(userData)) {
        return null
    }
    const picked = _.pick(userData, [
        'attributes',
        'authenticationFlowType',
        'keyPrefix',
        'preferredMFA',
        'userDataKey',
        'username',
    ])
    const cognitoGroups = _.get(
        userData,
        'signInUserSession.idToken.payload.cognito:groups',
        []
    )
    return { ...picked, cognitoGroups }
}

export const getUser = createAsyncThunk(
    'user/getUser',
    async (event, thunkAPI) => {
        const userData = await Auth.currentAuthenticatedUser()
        return parseCognitoUser(userData)
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, { payload: userData }) {
            state.userData = parseCognitoUser(userData)
            state.loading = false
        },
        unsetUser(state) {
            state.userData = null
            state.loading = false
        },
    },
    extraReducers: builder => {
        builder.addCase(getUser.pending, (state, action) => {
            state.loading = true
        })

        builder.addCase(getUser.fulfilled, (state, action) => {
            state.userData = action.payload
            state.loading = false
        })

        builder.addCase(getUser.rejected, (state, action) => {
            state.loading = false
        })
    },
})

export const userActions = userSlice.actions

export default userSlice
