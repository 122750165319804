import _ from 'lodash'
import { useSelector } from 'react-redux'

export const COGNITO_GROUP_ADMINS = 'Admins'
export const COGNITO_GROUPS = [COGNITO_GROUP_ADMINS]

const useUserInGroups = (...groups) => {
    const userData = useSelector(state => _.get(state.user, 'userData'))

    if (!userData) return false

    const cognitoGroups = _.get(userData, 'cognitoGroups')

    return _.reduce(
        groups,
        (result, group) => _.includes(cognitoGroups, group) && result,
        true
    )
}

export default useUserInGroups
