import { Box, Grid, Typography, useTheme } from '@mui/material'
import Hero from 'react-common-eks/dist/components/Hero'
import ImgIX from 'react-common-eks/dist/components/ImgIX'
import PageHeading from 'react-common-eks/dist/components/PageHeading'
import Paragraph from 'react-common-eks/dist/components/Paragraph'
import RicePaper from 'react-common-eks/dist/components/RicePaper'
import useBreadcrumbs from 'react-common-eks/dist/hooks/use-breadcrumbs'

const PAGE_TITLE = 'Shinto Muso Ryu Nihon Jodo Kai'

const MUSO_PHOTO = '/images/Muso-Gonnosuke-Katsuyoshi-Portrait.png'
const KAMINODA_PHOTO = '/images/Kaminoda-Tsunemori.jpeg'

const ShintoMusuRyuNihonJodoKai = () => {
    const theme = useTheme()
    useBreadcrumbs([{ label: 'Home', href: '/' }])

    const captionImgParams = {
        fit: 'crop',
        w: theme.breakpoints.values.sm,
    }

    return (
        <Paragraph
            component={'article'}
            id="organization-shinto-muso-ryu"
            itemScope
            itemType="https://schema.org/Organization"
        >
            <PageHeading itemProp="name" title={PAGE_TITLE} />
            <RicePaper page>
                <Grid
                    container
                    direction="row-reverse"
                    sx={{
                        backgroundColor: 'grey.200',
                        '@media (prefers-color-scheme: dark)': {
                            backgroundColor: 'grey.400',
                            color: theme =>
                                theme.palette.getContrastText(
                                    theme.palette.grey['400']
                                ),
                        },
                        mb: '1rem',
                    }}
                >
                    <Grid item sm={4} md={3}>
                        <Box
                            sx={[
                                {
                                    lineHeight: 0,
                                },
                            ]}
                        >
                            <ImgIX
                                itemProp="image"
                                src={MUSO_PHOTO}
                                params={{ ...captionImgParams, ar: '1:1' }}
                                width="100%"
                                alt="Muso Gonnosuke"
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        sm={8}
                        md={9}
                        component="section"
                        alignSelf="center"
                    >
                        <Hero
                            maxWidth={theme =>
                                0.67 * theme.breakpoints.values.md
                            }
                            margin="auto"
                        >
                            <Typography paragraph itemProp="description">
                                The classical Japanese weapons art of{' '}
                                <span itemProp="knowsAbout">jodo</span>,
                                approximately 400 years old, is built around
                                using a staff to defeat a swordsman. It was
                                invented by{' '}
                                <span
                                    itemProp="founder"
                                    itemScope
                                    itemType="https://schema.org/Person"
                                >
                                    <span itemProp="name">Muso Gonnosuke</span>
                                </span>{' '}
                                as a dueling art and rapidly evolved into a
                                policing art. The jo proves to be a formidable
                                weapon against a swordsman, and a skilled jo
                                practitioner can tune the amount of force to the
                                situation.
                            </Typography>
                        </Hero>
                    </Grid>
                </Grid>
                <Typography paragraph>
                    In addition to the jo, Shinto Muso Ryu incorporates
                    teachings from five other schools:
                </Typography>
                <ul>
                    <li
                        itemProp="knowsAbout"
                        itemScope
                        itemType="https://schema.org/Organization"
                    >
                        <span itemProp="name">Kasumi Shinto Ryu Kenjutsu</span>{' '}
                        (sword)
                    </li>
                    <li
                        itemProp="knowsAbout"
                        itemScope
                        itemType="https://schema.org/Organization"
                    >
                        <span itemProp="name">Uchida Ryu Tanjojutsu</span>{' '}
                        (walking stick)
                    </li>
                    <li
                        itemProp="knowsAbout"
                        itemScope
                        itemType="https://schema.org/Organization"
                    >
                        <span itemProp="name">Ikkaku Ryu Juttejutsu</span>{' '}
                        (truncheon and fan)
                    </li>
                    <li
                        itemProp="knowsAbout"
                        itemScope
                        itemType="https://schema.org/Organization"
                    >
                        <span itemProp="name">Isshin Ryu Kusarigamajutsu</span>{' '}
                        (sickle and chain)
                    </li>
                    <li
                        itemProp="knowsAbout"
                        itemScope
                        itemType="https://schema.org/Organization"
                    >
                        <span itemProp="name">Ittatsu Ryu Hojojutsu</span>{' '}
                        (rope-tying art)
                    </li>
                </ul>
                <span
                    itemScope
                    itemType="https://schema.org/Organization"
                    id="organization-nihon-jodokai"
                >
                    <span
                        itemProp="founder"
                        itemScope
                        itemType="https://schema.org/Person"
                        itemRef="person-kaminoda-tsunemori-sensei"
                    />
                    <span
                        itemProp="location"
                        itemScope
                        itemType="https://schema.org/Text"
                        itemRef="location-zoshokan-temple"
                    />
                    <Grid
                        container
                        sx={{
                            backgroundColor: 'grey.400',
                            color: theme =>
                                theme.palette.getContrastText(
                                    theme.palette.grey['400']
                                ),
                            '@media (prefers-color-scheme: dark)': {
                                backgroundColor: 'grey.600',
                                color: theme =>
                                    theme.palette.getContrastText(
                                        theme.palette.grey['600']
                                    ),
                            },
                            mb: '1rem',
                        }}
                    >
                        <Grid item sm={4} md={3}>
                            <Box
                                sx={[
                                    {
                                        lineHeight: 0,
                                    },
                                ]}
                            >
                                {/* TODO: Change this photo. to */}
                                <ImgIX
                                    itemProp="image"
                                    src={KAMINODA_PHOTO}
                                    params={{
                                        ...captionImgParams,
                                        ar: '4:5',
                                        'fp-x': 0.55,
                                        'fp-y': 0.33,
                                    }}
                                    width="100%"
                                    alt="Kaminoda Sensei"
                                />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            sm={8}
                            md={9}
                            component="section"
                            alignSelf="center"
                        >
                            <Hero
                                maxWidth={theme =>
                                    0.67 * theme.breakpoints.values.md
                                }
                                margin="auto"
                            >
                                <Typography paragraph sx={{ margin: 0 }}>
                                    Kaminoda Sensei is Menkyo Kaiden, Hachiman
                                    Hanshi, chief instructor at the Zoshokan
                                    Temple dojo in Tokyo.
                                </Typography>
                            </Hero>
                        </Grid>
                    </Grid>
                    <Typography paragraph itemProp="description">
                        The history of Shinto Muso Ryu lies in the island of
                        Kyushu, where for roughly three hundred years, only
                        retainers of the Kuroda clan in Fukuoka City were
                        allowed to practice the art. Instruction spread from
                        Kyushu during the 1930s as{' '}
                        <span
                            itemScope
                            itemType="https://schema.org/Person"
                            id="person-shimizu-takaji-sensei"
                        >
                            <span itemProp="name">Shimizu Takaji Sensei</span> (
                            <span
                                itemProp="birthDate"
                                itemScope
                                itemType="https://schema.org/Date"
                            >
                                1896
                            </span>
                            -
                            <span
                                itemProp="deathDate"
                                itemScope
                                itemType="https://schema.org/Date"
                            >
                                1978
                            </span>
                            )
                        </span>{' '}
                        moved to Tokyo and began training martial artists and
                        the police in the art. We follow the teachings of his
                        student and protege,{' '}
                        <span
                            itemScope
                            itemType="https://schema.org/Person"
                            itemRef="person-kaminoda-tsunemori-sensei"
                        >
                            Kaminoda Tsunemori Sensei
                        </span>{' '}
                        of <span itemProp="name">Nihon Jodokai</span>.
                    </Typography>

                    <Typography
                        paragraph
                        itemScope
                        itemType="https://schema.org/Person"
                        id="person-kaminoda-tsunemori-sensei"
                    >
                        Kaminoda Sensei is Menkyo Kaiden, Hachiman Hanshi, chief
                        instructor at the{' '}
                        <span id="location-zoshokan-temple">
                            Zoshokan Temple dojo in Tokyo
                        </span>
                        , and the recognized headmaster of{' '}
                        <span
                            itemProp="memberOf"
                            itemScope
                            itemType="https://schema.org/Organization"
                        >
                            <span itemProp="name">
                                Isshin Ryu Kusarigamajutsu
                            </span>
                        </span>
                        . He is a noted author and coauthor of authoritative
                        volumes on <span itemProp="knowsAbout">jodo</span>,{' '}
                        <span itemProp="knowsAbout">tanjo</span>,{' '}
                        <span itemProp="knowsAbout">kusarigama</span>,{' '}
                        <span itemProp="knowsAbout">kenjutsu</span>, and{' '}
                        <span itemProp="knowsAbout">jutte</span>. The successor
                        to{' '}
                        <span
                            itemScope
                            itemType="https://schema.org/Person"
                            itemRef="person-shimizu-takaji-sensei"
                        >
                            Shimizu Sensei
                        </span>{' '}
                        as the instructor for the 4th Division of the Tokyo riot
                        police charged with guarding the imperial family,{' '}
                        <span
                            itemScope
                            itemType="https://schema.org/Person"
                            itemRef="person-kaminoda-tsunemori-sensei"
                        >
                            Kaminoda Sensei
                        </span>{' '}
                        received a decoration from the Emperor of Japan in 2006
                        for his service to the Japanese police.
                    </Typography>
                </span>
            </RicePaper>
        </Paragraph>
    )
}

ShintoMusuRyuNihonJodoKai.defaultProps = {}

export default ShintoMusuRyuNihonJodoKai
