import { Link, Typography } from '@mui/material'

const BioContent = () => (
    <>
        <Typography paragraph>
            Shihan Edwards was a world-class competitor and won championships at
            every level. He competed on the Hakua Kai Japan National Team and
            the All Saitama Team. He trained with the top instructors and
            competitors while living in Japan in the 1960s.
        </Typography>

        <Typography paragraph>
            Shihan Edwards began teaching Hakua Kai Karate and settled in San
            Francisco in 1970 after his tour in the Air Force. He produced
            students who became gold medal winners in USA Championship
            tournaments and has had students on US National and IKF teams five
            times. He was one of the most sought-after referees/officials in the
            country under AAU and USA Karate Federation. He was featured on the
            CBS television show "48 Hours" while refereeing the final match in
            the Police and Fire World Games in Memphis, Tennessee. Shihan
            Edwards has continued studying, teaching, and bringing students to
            Japan each year for the past forty years.
        </Typography>

        <Typography paragraph>
            For his contribution to the traditional Japanese martial arts,
            Shihan Edwards was inducted into the{' '}
            <Link href="http://www.mastershalloffame.org/?q=content/mhof-inductee-list-2010">
                <span itemProp="award">Martial Arts Masters Hall of Fame</span>{' '}
                in 2010
            </Link>{' '}
            and the{' '}
            <Link href="http://www.nichibeikai.org/page2/index.html">
                <span itemProp="award">
                    Hokka Nichibeikai Bunka Hall of Fame
                </span>{' '}
                in 2016
            </Link>
            . The Martial Arts Masters Hall of Fame recognizes leaders of the
            martial arts community. The Bunka Hall of Fame's purpose is to honor
            those who have excelled and contributed to the culture of and
            bettered relationships in our community by enlightening all to the
            culture of Japan as practiced in America.
        </Typography>
    </>
)

export default BioContent
