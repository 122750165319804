import { Hub } from 'aws-amplify'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { getUser, userActions } from '../store/user-slice'

const useAuth = () => {
    const dispatch = useDispatch()

    const authCallback = useCallback(
        ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    dispatch(userActions.setUser(data))
                    break
                case 'signOut':
                    dispatch(userActions.unsetUser())
                    break
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    console.warn('Sign in failure', data)
                    break
                case 'customOAuthState':
                    break
                default:
                    console.debug('Unhandled auth event', event, data)
            }
        },
        [dispatch]
    )

    useEffect(() => Hub.listen('auth', authCallback), [authCallback])

    useEffect(() => {
        dispatch(getUser('useAuth'))
    }, [dispatch])
}

export default useAuth
