import { List, ListItem, Typography } from '@mui/material'
import ListItemLink from 'react-common-eks/dist/components/ListItemLink'

const ShihanAffiliations = () => (
    <>
        <Typography variant="h4" component="h3">
            Member Affiliations
        </Typography>
        <List>
            <ListItem
                disablePadding
                itemScope
                itemProp="memberOf"
                itemType="https://schema.org/Organization"
            >
                <ListItemLink
                    itemProp="url"
                    href="https://www.hakuakai.com"
                    target="_blank"
                >
                    <span itemProp="name">US Karate-Do Hakua Kai</span>
                </ListItemLink>
            </ListItem>
            <ListItem
                disablePadding
                itemScope
                itemProp="memberOf"
                itemType="https://schema.org/Organization"
            >
                <ListItemLink
                    itemProp="url"
                    href="https://www.rkhskusa.com"
                    target="_blank"
                >
                    <span itemProp="name">
                        Ryukyu Kobujutsu Hozon Shinko Kai USA
                    </span>
                </ListItemLink>
            </ListItem>
            <ListItem
                disablePadding
                itemScope
                itemProp="memberOf"
                itemType="https://schema.org/Organization"
            >
                <ListItemLink
                    itemProp="url"
                    href="/shinto-musu-ryu-nihon-jodo-kai"
                >
                    <span itemProp="name">Shinto Muso Ryu Nihon Jodo Kai</span>
                </ListItemLink>
            </ListItem>
            <ListItem
                disablePadding
                itemScope
                itemProp="memberOf"
                itemType="https://schema.org/Organization"
            >
                <ListItemLink
                    itemProp="url"
                    href="http://www.hakuakaikarate.org/home.html"
                    target="_blank"
                >
                    <span itemProp="name">Japan Karate-Do Hakua Kai</span>
                </ListItemLink>
            </ListItem>
            <ListItem
                disablePadding
                itemScope
                itemProp="memberOf"
                itemType="https://schema.org/Organization"
            >
                <ListItemLink
                    itemProp="url"
                    href="http://www.ryukyukobujutsuhozonshinkokai.org"
                    target="_blank"
                >
                    <span itemProp="name">
                        Ryukyu Kobujutsu Hozon Shinko Kai, Headquarters, Japan
                    </span>
                </ListItemLink>
            </ListItem>
        </List>
    </>
)

export default ShihanAffiliations
