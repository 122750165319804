import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialDonorValues = {
    money: '',
    equipment: '',
    volunteer: '',
    other: '',
}

const initialParentValues = {
    children: '',
}

const initialSchoolValues = {
    affiliation: '',
    affiliationLevel: '',
    dojo: '',
    style: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    previousTraining: [],
    references: [],
}

const initialStudentValues = {
    dojoId: '',
    yearsOfTraining: '',
    beltLevelId: '',
    experienceLevelId: '',
    previousTraining: [],
}

const initialValues = {
    userId: null,
    memberId: null,
    associationId: '',
    membership: '',
    publicName: '',
    birthDate: '',
    medical: '',
    phoneHome: '',
    phoneWork: '',
    phoneMobile: '',
    phonePreferred: 'home',
    email: '',
    emailAlternate: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
}

const STEP_DETAILS = [
    { form: 'applicant-selection' },
    { form: 'applicant-information' },
    { form: 'membership-information' },
]

const initialState = {
    step: 0,
    // stepsSkipped: [false, false, false],
    // stepsCompleted: [false, false, false],
    group: null,
    groupCache: {},
    applicantInformation: initialValues,
    membershipInformation: null,
    ...STEP_DETAILS[0],
}

export const renewal = createAsyncThunk(
    'applyWizard/initRenewal',
    async ({ user, memberId }, { dispatch, getState }) => {
        if (!user) return

        dispatch(applyWizardActions.setUser(user))

        let member
        if (memberId) {
            member = _.first(_.filter(user.members, { member_id: memberId }))
        }

        if (member) {
            dispatch(applyWizardActions.setMember(member))
        }

        if (user.role === 'student') {
            dispatch(applyWizardActions.setMembership('student'))
            dispatch(applyWizardActions.setGroup('student'))
        }
        dispatch(applyWizardActions.setStep(1))

        const state = getState()
        return _.get(state, 'applyWizard')
    }
)

export const applyWizardSlice = createSlice({
    name: 'applyWizard',
    devTools: true,
    initialState,
    reducers: {
        init(state, { payload: associationId }) {
            _.merge(state, initialState)
            state.applicantInformation.associationId = _.toString(associationId)
        },
        next(state) {
            _.merge(state, STEP_DETAILS[++state.step])
        },
        prev(state) {
            _.merge(state, STEP_DETAILS[--state.step])
        },
        setEmail(state, { payload: email }) {
            state.applicantInformation.email = email
            // console.log({ ...state.applicantInformation })
        },
        setStep(state, { payload: step }) {
            state.step = step
            // console.log({ ...state.applicantInformation })
        },
        setMembership(state, { payload: membership }) {
            state.applicantInformation.membership = membership
            // console.log({ ...state.applicantInformation })
        },
        setGroup(state, { payload: group }) {
            if (group === state.group) {
                return
            }

            if (!_.isNull(state.group)) {
                state.groupCache[state.group] = state.membershipInformation
            }

            state.group = group
            if (_.has(state.groupCache, group)) {
                state.membershipInformation = state.groupCache[group]
            } else if (group === 'donor') {
                state.membershipInformation = initialDonorValues
            } else if (group === 'parent') {
                state.membershipInformation = initialParentValues
            } else if (group === 'school') {
                state.membershipInformation = initialSchoolValues
            } else if (group === 'student') {
                state.membershipInformation = initialStudentValues
            } else {
                throw new Error(`Unrecognized group, "${group}"`)
            }
            // console.log(_.pick(state, ['group', 'membershipInformation']))
        },
        putApplicantInformation(state, { payload: applicantInformation }) {
            state.applicantInformation = applicantInformation
            // console.log({ ...state.applicantInformation })
        },
        putMembershipInformation(state, { payload: membershipInformation }) {
            state.membershipInformation = membershipInformation
            // console.log({ ...state.membershipInformation })
        },
        setUser(state, { payload: user }) {
            const transform = {
                userId: 'user_id',
                email: 'email',
                emailAlternate: 'email_alternate',
                phoneHome: 'phone_home',
                phoneWork: 'phone_work',
                phoneMobile: 'phone_mobile',
                phonePreferred: 'phone_preferred',
                address1: 'address1',
                address2: 'address2',
                city: 'city',
                state: 'state',
                zipCode: 'zip_code',
            }

            _.each(transform, (source, target) => {
                state.applicantInformation[target] =
                    _.get(user, source, '') || ''
            })
            if (state.applicantInformation.userId === '')
                delete state.applicantInformation.userId

            // console.log({ ...state.applicantInformation })
        },
        setMember(state, { payload: member }) {
            const transform = {
                memberId: 'member_id',
                publicName: 'public_name',
                birthDate: 'birth_date',
            }

            _.each(transform, (source, target) => {
                state.applicantInformation[target] =
                    _.get(member, source, '') || ''
            })
            if (state.applicantInformation.memberId === '')
                delete state.applicantInformation.memberId

            // console.log({ ...state.applicantInformation })
        },
    },
    extraReducers: builder => {
        builder.addCase(renewal.fulfilled, (state, action) => {
            _.merge(state, action.payload)
        })
    },
})

export const applyWizardActions = applyWizardSlice.actions

export default applyWizardSlice
