import * as Sentry from '@sentry/react'
import createStore from 'react-common-eks/dist/store'

import applyWizardSlice from './apply-wizard-slice'
import userSlice from './user-slice' // See https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/

// See https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
})

const store = createStore({
    reducer: {
        // ...your other reducers here
        applyWizard: applyWizardSlice.reducer,
        user: userSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['user/setUser'],
            },
        }),
    enhancers: [sentryReduxEnhancer],
})

export default store
