import _ from 'lodash'

const awsExports = {
    aws_project_region: _.get(process.env, 'REACT_APP_AWS_PROJECT_REGION'),
    Auth: {
        identityPoolId: _.get(process.env, 'REACT_APP_AUTH_IDENTITY_POOL_ID'),
        region: _.get(process.env, 'REACT_APP_AUTH_REGION'),
        userPoolId: _.get(process.env, 'REACT_APP_AUTH_USER_POOL_ID'),
        userPoolWebClientId: _.get(
            process.env,
            'REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID'
        ),
        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: _.get(process.env, 'REACT_APP_AUTH_OAUTH_DOMAIN'),
            scope: [
                'phone',
                'email',
                'profile',
                'openid',
                'aws.cognito.signin.user.admin',
            ],
            redirectSignIn: `${window.location.protocol}//${window.location.host}/me`,
            redirectSignOut: `${window.location.protocol}//${window.location.host}`,
            responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
    },
    aws_appsync_region: _.get(process.env, 'REACT_APP_AWS_APPSYNC_REGION'),
    aws_appsync_graphqlEndpoint: _.get(
        process.env,
        'REACT_APP_APPSYNC_GRAPHQL_ENDPOINT'
    ),
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
}

export default awsExports
